import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SectionTitle } from '../../headers/SectionTitle';
import { SelectableImage } from '../../forms/inputs/SelectableImage';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import icon100 from '../../../assets/icons/100x100.png';
import { TYPE_1 } from '../../../constants/ObjectTypes';
import { BASEBOARDS } from '../../../constants/Presets';
import type1Upright from '../../../assets/img/type_1_upright.png';

export const TypeSelect = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.configurationModal.';
	const constantsPrefix = 'constants.types.';

	const images = require.context('../../../assets/img/configuration', true);

	useEffect(() => {
		if (props.types.length === 1) {
			props.setType(props.types[0]);
		}
	}, [props.selectedOptions.material]);

	const getImage = type => {
		try {
			if (type === TYPE_1 && props.selectedOptions.preset === BASEBOARDS) {
				return type1Upright;
			} else {
				return images('./' + type.toLowerCase() + '.png');
			}
		} catch (e) {
			return icon100;
		}
	};

	return (
		<>
			{props.isVisible && (
				<>
					<SectionTitle content={t(prefix + 'typeTitle')} />
					<div className="form-group--selectable__holder" data-cy={props.dataCy}>
						{props.types.map((type, index) => {
							return (
								<SelectableImage
									dataCy={`typeSelect-${type.toLowerCase()}`}
									key={index}
									image={getImage(type)}
									onClick={() => props.setType(type)}
									name="type"
									content={t(constantsPrefix + type)}
									selected={props.selectedOptions.type === type}
								/>
							);
						})}
					</div>
				</>
			)}
		</>
	);
};

TypeSelect.propTypes = {
	isVisible: PropTypes.bool,
	types: PropTypes.array,
	selectedOptions: PropTypes.object,
	setType: PropTypes.func.isRequired,
	dataCy: PropTypes.string,
};
