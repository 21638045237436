import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
	ConfirmationModal,
	getSideNameByPreset,
	Operation,
	OperationHelper,
	RadioButton,
	SectionTitle,
} from '../../internal';
import { INNER, OUTER } from '../../constants/Angles';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { DANGER, WARNING } from '../../constants/Variants';
import innerAngleLeftIcon from '../../assets/img/config-dikteverstek-links-binnenhoek.png';
import outerAngleLeftIcon from '../../assets/img/config-dikteverstek-links-buitenhoek.png';
import innerAngleRightIcon from '../../assets/img/config-dikteverstek-rechts-binnenhoek.png';
import outerAngleRightIcon from '../../assets/img/config-dikteverstek-rechts-buitenhoek.png';
import outerAngleTopIcon from '../../assets/img/config-dikteverstek-boven-buitenhoek.png';
import innerAngleTopIcon from '../../assets/img/config-dikteverstek-boven-binnenhoek.png';
import innerAngleBottomIcon from '../../assets/img/config-dikteverstek-onder-binnenhoek.png';
import outerAngleBottomIcon from '../../assets/img/config-dikteverstek-onder-buitenhoek.png';
import { useForm } from 'react-hook-form';
import { ValidationMessage } from '../shared/ValidationMessage';
import { CENTIMETERS, DEGREES } from '../../constants/Values';
import { HEIGHT_COUPE, PROHIBITED_OPERATION_TYPES } from '../../constants/OperationTypes';
import { createOperation, deleteOperation, updateOperation } from './index';
import { BACK, BOTTOM, FRONT, LEFT, RIGHT, TOP } from '../../constants/ObjectSides';
import { VERTICAL_DISPLAYED_PRESETS } from '../../constants/Presets';
import { parseNumber } from '../../classes/helpers/StringHelper';
import { ModalHolder } from './ModalHolder';
import { Message } from '../messages/Message';

export function HeightCoupeModal(props) {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'modals.heightCoupeModal.';
	const validationPrefix = 'validation.';

	const [selectedSide, setSelectedSide] = useState('');
	const [selectedAngleType, setSelectedAngleType] = useState('');
	const [confirmationModalIsActive, setConfirmationModalIsActive] = useState(false);
	const [availableSides, setAvailableSides] = useState([]);
	const [inputType, setInputType] = useState(DEGREES);
	const [formIsDisabled, setFormIsDisabled] = useState(false);

	const { errors, register, handleSubmit } = useForm();

	const { currentConfiguration, currentPiece, currentOperation } = useSelector(state => state.offerReducer);
	const canEdit = useSelector(state => state.generalReducer.canEdit);

	useEffect(() => {
		if (props.isActive) {
			setFormIsDisabled(!canEdit || currentOperation?.connectedCoupe);
		} else {
			setFormIsDisabled(false);
		}
	}, [props.isActive, canEdit, currentOperation]);

	useEffect(() => {
		if (currentOperation == null) {
			let sides = [];

			if (currentPiece) {
				sides = currentPiece.getAvailableSides(PROHIBITED_OPERATION_TYPES.HEIGHT_COUPE);
				setAvailableSides(sides);
			}

			if (sides.length) {
				setSelectedSide(sides[0]);
			} else {
				setSelectedSide('');
			}

			setSelectedAngleType(INNER);
			setInputType(DEGREES);
		} else {
			let sides = [];

			if (currentPiece) {
				sides = currentPiece.getAvailableSides(PROHIBITED_OPERATION_TYPES.HEIGHT_COUPE);
				sides.push(currentOperation.side);
				setAvailableSides(sides);
			}

			setSelectedAngleType(currentOperation.angle);
			setSelectedSide(currentOperation.side);
			setInputType(currentOperation.additionalDimension.type);
		}
	}, [props.isActive, currentOperation]);

	const getAngleIcon = angle => {
		let innerAngleIcon;
		let outerAngleIcon;

		if (VERTICAL_DISPLAYED_PRESETS.includes(currentConfiguration?.options.preset)) {
			if (selectedSide === LEFT) {
				innerAngleIcon = innerAngleBottomIcon;
				outerAngleIcon = outerAngleBottomIcon;
			} else {
				innerAngleIcon = innerAngleTopIcon;
				outerAngleIcon = outerAngleTopIcon;
			}
		} else {
			if (selectedSide === LEFT) {
				innerAngleIcon = innerAngleLeftIcon;
				outerAngleIcon = outerAngleLeftIcon;
			} else {
				innerAngleIcon = innerAngleRightIcon;
				outerAngleIcon = outerAngleRightIcon;
			}
		}

		return angle === INNER ? innerAngleIcon : outerAngleIcon;
	};

	const createHeightCoupe = fields => {
		let heightCoupe = new Operation(0, HEIGHT_COUPE);
		heightCoupe.side = selectedSide;
		heightCoupe.angle = selectedAngleType;
		heightCoupe.dimensions.width = currentPiece.dimensions.height;
		heightCoupe.dimensions.length = OperationHelper.getBottomSideLengthOfHeightCoupeByAngleDegrees(
			fields.dimensionInput,
			currentPiece.dimensions.height,
		);
		heightCoupe.additionalDimension.value = parseNumber(fields.dimensionInput);
		heightCoupe.additionalDimension.type = inputType;

		createOperation(heightCoupe, () => props.onClose(true), t(prefix + 'heightCoupeCreateFailed'));
	};

	const updateHeightCoupe = fields => {
		currentOperation.side = selectedSide;
		currentOperation.angle = selectedAngleType;
		currentOperation.dimensions.length = OperationHelper.getBottomSideLengthOfHeightCoupeByAngleDegrees(
			fields.dimensionInput,
			currentPiece.dimensions.height,
		);
		currentOperation.dimensions.width = currentPiece.dimensions.height;
		currentOperation.additionalDimension.value = parseNumber(fields.dimensionInput);
		currentOperation.additionalDimension.type = inputType;

		updateOperation(currentOperation, () => props.onClose(true), t(prefix + 'updateFailed'));
	};

	const deleteHeightCoupe = () => {
		deleteOperation(
			currentOperation,
			() => props.onClose(true),
			t(prefix + 'heightCoupeDeleteSuccess'),
			t(prefix + 'deleteFailed'),
		);
	};

	const closeModal = fields => {
		if (!fields) {
			props.onClose();
			return;
		}

		if (currentOperation) {
			updateHeightCoupe(fields);
		} else {
			createHeightCoupe(fields);
		}
	};

	const onAngleTypeChange = value => {
		if (value !== INNER && value !== OUTER) return;

		setSelectedAngleType(value);
	};

	const onSideChange = value => {
		if (value === TOP || value === BOTTOM) return;

		setSelectedSide(value);
	};

	const closeConfirmationModal = userHasAccepted => {
		if (userHasAccepted) {
			deleteHeightCoupe();
		}
		setConfirmationModalIsActive(false);
	};

	const renderInputTypeButtons = () => {
		const defaultClass = 'button ';
		const activeClass = 'button--active';
		const inActiveClass = 'button--outline';
		const degreesButtonClass = inputType === DEGREES ? defaultClass + activeClass : defaultClass + inActiveClass;
		const centimetersButtonClass =
			inputType === CENTIMETERS ? defaultClass + activeClass : defaultClass + inActiveClass;

		return (
			<div className="button-group">
				<button
					data-cy={`heightCoupeModal-${DEGREES.toLowerCase()}Button`}
					type="button"
					className={degreesButtonClass}
					disabled={formIsDisabled}
					onClick={() => setInputType(DEGREES)}
				>
					{t(prefix + 'degreesButton')}
				</button>
				<button
					data-cy={`heightCoupeModal-${CENTIMETERS.toLowerCase()}Button`}
					type="button"
					className={centimetersButtonClass}
					disabled={formIsDisabled}
					onClick={() => setInputType(CENTIMETERS)}
				>
					{t(prefix + 'centimetersButton')}
				</button>
			</div>
		);
	};

	const renderHeightCoupeDimensionInput = () => {
		const placeholder = inputType === DEGREES ? t(prefix + 'degreesPlaceholder') : t(prefix + 'centimetersPlaceholder');

		return (
			<div className="form-group">
				<label>
					<input
						name="dimensionInput"
						data-cy={'heightCoupeModal-dimensionInput'}
						type="text"
						placeholder={placeholder}
						defaultValue={currentOperation?.additionalDimension.value}
						disabled={formIsDisabled}
						ref={register({
							required: true,
							min: 1,
							max: 89,
						})}
					/>
				</label>
				{errors.dimensionInput?.type === 'required' ? (
					<ValidationMessage content={t(validationPrefix + 'required')} variant={DANGER} />
				) : null}
				{errors.dimensionInput?.type === 'min' ? (
					<ValidationMessage content={t(validationPrefix + 'min', { min: 1 })} variant={DANGER} />
				) : null}
				{errors.dimensionInput?.type === 'max' ? (
					<ValidationMessage content={t(validationPrefix + 'max', { max: 89 })} variant={DANGER} />
				) : null}
			</div>
		);
	};

	const renderFooterButtons = () => {
		let secondaryButtonStyle = 'button button--outline';
		let secondaryButtonContent = t(prefix + 'cancelButton');
		let secondaryButtonOnClick = () => closeModal();

		if (currentOperation != null && canEdit) {
			secondaryButtonStyle = 'button button--danger';
			secondaryButtonContent = t(prefix + 'deleteButton');
			secondaryButtonOnClick = () => setConfirmationModalIsActive(true);
		}

		const submitButton = (
			<button
				key={'heightCoupeModalSubmitButton'}
				data-cy={'heightCoupeModal-submitButton'}
				className="button button--primary"
				type="submit"
				form="heightCoupeForm"
				disabled={!selectedSide}
			>
				{t(prefix + 'doneButton')}
			</button>
		);

		let buttons = [
			<button
				key={'heightCoupeModalCancelButton'}
				type="button"
				data-cy={'heightCoupeModal-deleteButton'}
				className={secondaryButtonStyle}
				onClick={secondaryButtonOnClick}
			>
				{secondaryButtonContent}
			</button>,
		];

		if (!formIsDisabled) {
			buttons.unshift(submitButton);
		}

		return buttons;
	};

	const renderSideSelect = () => {
		return (
			<>
				<SectionTitle content={t(prefix + 'sideTitle')} />

				{[FRONT, LEFT, BACK, RIGHT].map((side, index) => {
					const sideName = getSideNameByPreset(currentConfiguration?.options.preset, side);

					return (
						<RadioButton
							dataCy={`heightCoupeModal-sideSelect-${side.toLowerCase()}`}
							key={`heightCoupeModalSideRadioButton${index}`}
							content={t('constants.objectSides.' + sideName)}
							onChange={() => onSideChange(side)}
							checked={selectedSide === side}
							name="side"
							disabled={!availableSides.includes(side) || formIsDisabled}
						/>
					);
				})}
			</>
		);
	};

	const renderAngleForm = () => {
		return (
			<>
				<SectionTitle content={t(prefix + 'angleTitle')} />

				{[INNER, OUTER].map((angle, index) => {
					return (
						<RadioButton
							dataCy={`heightCoupeModal-angleSelect-${angle.toLowerCase()}`}
							key={`heightCoupeAngleRadioButton${index}`}
							image={getAngleIcon(angle)}
							onChange={() => onAngleTypeChange(angle)}
							name="angleRadio"
							checked={selectedAngleType === angle}
							disabled={formIsDisabled}
							content={t('constants.angles.' + angle)}
						/>
					);
				})}
			</>
		);
	};

	return (
		<ModalHolder isActive={props.isActive}>
			<ConfirmationModal
				isActive={confirmationModalIsActive}
				onClose={userHasAccepted => closeConfirmationModal(userHasAccepted)}
				content={t('modals.heightCoupeModal.deleteHeightCoupe')}
			/>

			<Modal show={props.isActive} onHide={() => closeModal()} animation={true} data-cy="heightCoupe-modal">
				<Modal.Header className="modal-header">{t(prefix + 'header')}</Modal.Header>
				<Modal.Body>
					<form id="heightCoupeForm" onSubmit={handleSubmit(closeModal)}>
						<p>{t(prefix + 'description')}</p>

						{renderSideSelect()}
						{renderAngleForm()}

						<SectionTitle
							content={inputType === DEGREES ? t(prefix + 'degreesTitle') : t(prefix + 'centimetersTitle')}
						/>
						<div className="list__item">
							{renderHeightCoupeDimensionInput()}
							{renderInputTypeButtons()}
						</div>
					</form>

					{formIsDisabled && <Message content={t(prefix + 'heightCoupeFormDisabledMessage')} variant={WARNING} />}
				</Modal.Body>
				<Modal.Footer>{renderFooterButtons()}</Modal.Footer>
			</Modal>
		</ModalHolder>
	);
}

HeightCoupeModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
