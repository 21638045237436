import React, { useEffect, useState } from 'react';
import {
	AddOfferModal,
	AppContent,
	AppHolder,
	ConfirmationModal,
	EmptyOfferList,
	InfoModal,
	LoadingMessage,
	MessageHolder,
	NavigationBar,
	Offer,
} from '../../../internal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentOffer } from '../../../actions/OfferActions';
import {
	addAlertMessage,
	removeAlertMessage,
	setActiveInfoModal,
	setShouldUpdatePrice,
	setWindowIsLoading,
} from '../../../actions/GeneralActions';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { DANGER } from '../../../constants/Variants';
import { OfferList } from './OfferList';
import { usePrevious } from '../../../classes/hooks/usePrevious';
import { ADMIN, MULTI_BRANCH_USER, SUPERVISOR, USER } from '../../../constants/RoleNames';
import { setCurrentCompanyBranch } from '../../../actions/CompanyActions';
import { useOffers } from '../../../classes/hooks/useOffers';
import { useCustomers } from '../../../classes/hooks/useCustomers';
import ArticleModal from '../../modals/ArticleModal';
import { setArticleConfig } from '../../../actions/OutdoorShopActions';
import { CompanyBranchSelect } from '../../shared/CompanyBranchSelect';
import { CompanyBranchService } from '../../../classes/services/CompanyBranchService';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';
import { throttle } from 'lodash';
import history from '../../../config/history';
import { Pagination } from '../../shared/Pagination';

export function Offers() {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.offers.';

	const [companyBranches, setCompanyBranches] = useState([]);
	const [offerModalIsActive, setOfferModalIsActive] = useState(false);
	const [editArticleModalIsActive, setEditArticleModalIsActive] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	const { activeInfoModal } = useSelector(state => state.generalReducer);
	const alertMessages = useSelector(state => state.generalReducer.alertMessages);

	const { reloadCustomers, customers } = useCustomers();
	const { loadOffers, paginationData, createOffer, offers, setIsUpdatingArticleOffers } = useOffers();

	const companyBranchService = new CompanyBranchService();

	const { windowIsLoading, shouldUpdatePrice, searchKeyword, confirmationModal } = useSelector(
		state => state.generalReducer,
	);
	const { currentOffer } = useSelector(state => state.offerReducer);
	const { currentCompanyBranch, currentCompanyBranchId = currentCompanyBranch?.id } = useSelector(
		state => state.companyReducer,
	);
	const previousCompanyBranch = usePrevious(currentCompanyBranch);

	const dispatch = useDispatch();
	const previousKeyword = usePrevious(searchKeyword);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			dispatch(setCurrentOffer(null));
			dispatch(setWindowIsLoading(true));

			if (!hasOneOfRoles([USER, MULTI_BRANCH_USER])) {
				companyBranchService.getAll().then(response => {
					if (response.success && mounted) {
						setCompanyBranches(response.data);
					}
				});
			}
			if (hasOneOfRoles(MULTI_BRANCH_USER)) {
				companyBranchService.getByCompany().then(response => {
					if (response.success && mounted) {
						setCompanyBranches(response.data);
					}
				});
			}

			const ignore = loadOffers();
		}

		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		if (hasOneOfRoles(USER)) return;

		if (previousCompanyBranch?.id !== currentCompanyBranchId) {
			const ignore = loadOffers();
		}
	}, [currentCompanyBranchId]);

	useEffect(() => {
		if (shouldUpdatePrice) {
			const ignore = loadOffers(currentPage);

			dispatch(setShouldUpdatePrice(false));
		}
	}, [shouldUpdatePrice]);

	useEffect(() => {
		// No type check because it at first it is undefined, and later possible null
		// There should be no refresh after a change from undefined to null
		if (previousKeyword != searchKeyword) {
			const ignore = loadOffers();
		}
	}, [searchKeyword]);

	useEffect(() => {
		loadOffers(currentPage);
	}, [currentPage]);

	const loadNextPage = throttle(() => {
		const ignore = loadOffers();
	});

	const updateCurrentCompanyBranch = id => {
		const newCompanyBranch = companyBranches.find(c => c.id === parseInt(id));

		if (!newCompanyBranch) {
			dispatch(addAlertMessage(DANGER, t(prefix + 'messages.companyDoesNotExist')));
			return;
		}

		dispatch(setCurrentCompanyBranch(newCompanyBranch));
	};

	const closeOfferModal = (offer, companyBranchId) => {
		setOfferModalIsActive(false);

		if (!offer) return;

		const { name, customer, margin, vat, isVisible } = offer;

		createOffer(name, customer, margin, vat, companyBranchId, isVisible).then(offer => {
			if (customer && customer.id === 0) {
				reloadCustomers();
			}

			history.push(`/offers/${offer.id}`);
		});
	};

	const closeEditArticleModal = shouldUpdate => {
		dispatch(setArticleConfig(null));
		setEditArticleModalIsActive(false);
		setIsUpdatingArticleOffers(shouldUpdate);

		if (shouldUpdate) {
			dispatch(setShouldUpdatePrice(shouldUpdate));
		}
	};

	return (
		<AppHolder>
			{windowIsLoading ? <LoadingMessage variant="overlay" /> : null}

			<InfoModal
				isActive={activeInfoModal?.isActive}
				onClose={() => dispatch(setActiveInfoModal(false, ''))}
				content={activeInfoModal?.content}
			/>

			<AddOfferModal
				isActive={offerModalIsActive}
				onClose={(offer, companyBranchId) => closeOfferModal(offer, companyBranchId)}
				offer={currentOffer ?? new Offer()}
				customers={customers}
				companyBranches={companyBranches}
			/>

			<ConfirmationModal
				isActive={confirmationModal.isActive}
				onClose={hasAccepted => confirmationModal.onAccept(hasAccepted)}
				content={confirmationModal.content}
			/>

			{editArticleModalIsActive && (
				<ArticleModal isEditing isActive={editArticleModalIsActive} onClose={closeEditArticleModal} />
			)}

			<NavigationBar />

			{!windowIsLoading && (
				<>
					{offers.length < 1 && !searchKeyword && (
						<AppContent>
							{hasOneOfRoles([ADMIN, SUPERVISOR, MULTI_BRANCH_USER]) && (
								<CompanyBranchSelect className="p-l-4 p-r-4 p-t-2" branches={companyBranches} />
							)}
							<EmptyOfferList openCreateModal={() => setOfferModalIsActive(true)} />
						</AppContent>
					)}
				</>
			)}

			{!!((windowIsLoading && searchKeyword) || !currentOffer) && (
				<AppContent>
					<OfferList
						title={t(prefix + 'sideBarTitle')}
						offers={offers}
						companyBranch={currentCompanyBranch}
						branches={companyBranches}
						onCompanyChange={companyId => updateCurrentCompanyBranch(companyId)}
						openModal={() => setOfferModalIsActive(true)}
					/>
					{!windowIsLoading && (
						<Pagination
							paginationSettings={paginationData}
							changePage={pagination => setCurrentPage(pagination.currentPage)}
						/>
					)}
				</AppContent>
			)}

			<MessageHolder messages={alertMessages} onClose={index => dispatch(removeAlertMessage(index))} />
		</AppHolder>
	);
}
