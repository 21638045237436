import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConfigurationList } from '../../lists/ConfigurationList';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { Title } from '../../headers/Title';
import ArticleList from '../../lists/ArticleList';
import { AppContent } from '../../layout/AppContent';
import { Order } from '../../../classes/models/Order';
import { OrderDetailAppHeader } from './OrderDetailAppHeader';
import { LogService } from '../../../classes/services/LogService';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';
import { LogsModal } from '../../modals/LogsModal';
import { ADMIN, SUPERVISOR } from '../../../constants/RoleNames';
import { addAlertMessage, setWindowIsLoading } from '../../../actions/GeneralActions';
import { DANGER, SUCCESS } from '../../../constants/Variants';
import { captureException } from '@sentry/react';
import { useDispatch } from 'react-redux';
import { OrderService } from '../../../classes/services/OrderService';
import history from '../../../config/history';

export const OrderDetail = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.orders.';

	const [logsModalIsActive, setLogsModalIsActive] = useState(false);
	const [logs, setLogs] = useState([]);
	const dispatch = useDispatch();

	const orderService = new OrderService();

	const logService = new LogService();

	useEffect(() => {
		if (hasOneOfRoles([ADMIN, SUPERVISOR])) {
			logService.getForOrder(props.order?.id).then(response => {
				// Only get the logs created after the order was created
				setLogs(response.data.filter(l => l.createdAt.isAfter(props.order.createdAt)));
			});
		}
	}, []);

	const duplicateOrderToOffer = () => {
		dispatch(setWindowIsLoading(true));

		orderService
			.duplicateOrder(props.order.id)
			.then(response => {
				if (response.success) {
					dispatch(addAlertMessage(SUCCESS, t(prefix + 'duplicateOrderSuccessMessage')));
					history.push('/offers/' + response.data.id);
				} else {
					throw Error(response.data.message);
				}
			})
			.catch(e => {
				captureException(e);
				dispatch(addAlertMessage(DANGER, t(prefix + 'duplicateOrderFailedMessage')));
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	return (
		<AppContent>
			{hasOneOfRoles([ADMIN, SUPERVISOR]) && (
				<LogsModal isActive={logsModalIsActive} closeModal={() => setLogsModalIsActive(false)} logs={logs} />
			)}

			<OrderDetailAppHeader
				order={props.order}
				onEditClick={() => props.onEditOrderClick()}
				onDeliveryDateUpdateClick={() => props.onDeliveryDateUpdateClick()}
				onOpenLogsClick={() => setLogsModalIsActive(true)}
				onDuplicateClick={() => duplicateOrderToOffer()}
			/>

			{!!props.order?.configurations.length && (
				<>
					<div className="m-l-4">
						<Title>{t(prefix + 'configurationsListTitle')}</Title>
					</div>

					<ConfigurationList configurations={props.order.configurations} />
				</>
			)}

			{!!(props.order && props.order.outdoorConfigurations.length) && (
				<>
					<div className="m-l-4">
						<Title>{t(prefix + 'outdoorConfigurationsListTitle')}</Title>
					</div>
					<ArticleList outdoorConfigurations={props.order.outdoorConfigurations} isInOrder />
				</>
			)}
		</AppContent>
	);
};

OrderDetail.propTypes = {
	order: PropTypes.instanceOf(Order),
	onEditOrderClick: PropTypes.func.isRequired,
	onDeliveryDateUpdateClick: PropTypes.func.isRequired,
};
