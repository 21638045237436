import axios from 'axios';
import { setIsAuthenticated, setRefreshModalIsActive } from '../../actions/GeneralActions';
import { debounce } from 'lodash';
import packageJson from '../../../package.json';
import { setCurrentCompanyBranch } from '../../actions/CompanyActions';

const httpService = {
	setupInterceptors: (store, history) => {
		const openRefreshModal = debounce(() => {
			store.dispatch(setRefreshModalIsActive(true));
		}, 500);

		const maintenancePath = '/maintenance';

		axios.interceptors.response.use(
			response => {
				return response;
			},
			error => {
				if (error.response?.status === 401) {
					history.push('/login');
					store.dispatch(setIsAuthenticated(false));
					store.dispatch(setCurrentCompanyBranch(null));
				}

				if (error.response?.status === 503 && history.location.pathname !== maintenancePath) {
					// Backend in maintenance mode
					history.push(maintenancePath);
				}

				return error.response;
			},
		);

		axios.interceptors.response.use(response => {
			if (response) {
				if (
					process.env.REACT_APP_ENV !== 'testing' &&
					process.env.NODE_ENV === 'production' &&
					response.headers['client-version'] &&
					packageJson.version !== response.headers['client-version']
				) {
					openRefreshModal();
				}
			}

			return response;
		});
	},
};

export { httpService };
