import * as React from 'react';
import { useEffect, useState } from 'react';
import { AppHolder } from '../layout/AppHolder';
import { AppContent } from '../layout/AppContent';
import { SideBar } from '../navigation/SideBar';
import { SidebarListItem } from '../lists/items/SidebarListItem';
import { NavigationBar } from '../navigation/NavigationBar';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { AppHeader } from '../headers/AppHeader';
import { LocalStorageHelper } from '../../classes/helpers/LocalStorageHelper';
import { UserService } from '../../classes/services/UserService';
import { useDispatch, useSelector } from 'react-redux';
import { MessageHolder } from '../messages/MessageHolder';
import {
	addAlertMessage,
	removeAlertMessage,
	setIsAuthenticated,
	setUserSettings,
	setWindowIsLoading,
} from '../../actions/GeneralActions';
import { DANGER, SUCCESS } from '../../constants/Variants';
import { LoadingMessage } from '../messages/LoadingMessage';
import { ConfirmationModal } from '../modals/ConfirmationModal';
import { AuthService } from '../../classes/services/AuthService';
import { setCurrentConfiguration, setCurrentOffer, setCurrentPiece } from '../../actions/OfferActions';
import history from '../../config/history';
import { ChangePasswordForm } from './settings/ChangePasswordForm';
import { DeliveryAddressesManagement } from './settings/DeliveryAddressesManagement';
import { CompanySettings } from './settings/CompanySettings';
import { LanguageSelect } from '../forms/selects/LanguageSelect';
import { PriceTypeSelect } from './settings/PriceTypeSelect';
import { UserSettings } from '../../classes/services/UserSettings';
import { UserSettingsService } from '../../classes/services/UserSettingsService';
import { setCurrentCompanyBranch } from '../../actions/CompanyActions';

export const Settings = props => {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.settings.';

	const PROFILE = 'PROFILE';
	const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
	const COMPANY_SETTINGS = 'COMPANY_SETTINGS';
	const DELIVERY_ADDRESSES = 'DELIVERY_ADDRESSES';

	const [currentView, setCurrentView] = useState(PROFILE);
	const [confirmationModalIsActive, setConfirmationModalIsActive] = useState(false);
	const [selectedPriceType, setSelectedPriceType] = useState('');

	const { currentUser, alertMessages, windowIsLoading, userSettings } = useSelector(state => state.generalReducer);

	const dispatch = useDispatch();

	const userService = new UserService();
	const authService = new AuthService();
	const userSettingsService = new UserSettingsService();

	useEffect(() => {
		let isMounted = true;

		if (userSettings) {
			setSelectedPriceType(userSettings.priceType);
		}

		return () => {
			isMounted = false;
		};
	}, [userSettings?.priceType]);

	const handlePasswordChange = data => {
		dispatch(setWindowIsLoading(true));

		userService
			.changePassword(data, currentUser?.id)
			.then(response => {
				if (response.success) {
					dispatch(addAlertMessage(SUCCESS, t(prefix + 'changePasswordSuccess')));
				} else if (response.status === 406) {
					dispatch(addAlertMessage(DANGER, t(prefix + 'changePasswordFailedWrongPassword')));
				} else {
					dispatch(addAlertMessage(DANGER, t(prefix + 'changePasswordFailed')));
				}
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'changePasswordFailed')));
				throw error;
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const setPriceType = priceType => {
		setSelectedPriceType(priceType);
	};

	const saveSettings = () => {
		if (userSettings.priceType === selectedPriceType) {
			return;
		}

		const newUserSettings = Object.assign(new UserSettings(), userSettings);
		newUserSettings.priceType = selectedPriceType;

		dispatch(setWindowIsLoading(true));

		userSettingsService
			.update(newUserSettings)
			.then(response => {
				if (response.data.success) {
					dispatch(setUserSettings(response.data.data));
				} else {
					dispatch(addAlertMessage(DANGER, t(prefix + 'saveSettingsFailed')));
				}
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const renderProfileSettings = () => {
		return (
			<div className="p-l-4 p-r-4">
				<div className="m-b-2">
					<div className="form-group">
						<label>{t(prefix + 'emailLabel')}</label>
						<input type="email" defaultValue={currentUser?.email} disabled />
					</div>
				</div>

				<LanguageSelect disabled label={t(prefix + 'languageLabel')} value={LocalStorageHelper.getLanguage()} />

				{selectedPriceType && (
					<PriceTypeSelect value={selectedPriceType} onChange={priceType => setPriceType(priceType)}></PriceTypeSelect>
				)}

				<button
					type="button"
					className="button button--primary"
					onClick={() => saveSettings()}
					disabled={userSettings?.priceType === selectedPriceType}
				>
					{t(prefix + 'saveButton')}
				</button>
			</div>
		);
	};

	const renderChangePassword = () => {
		return <ChangePasswordForm handlePasswordChange={data => handlePasswordChange(data)} />;
	};

	const closeConfirmationModal = confirmed => {
		if (confirmed) {
			dispatch(setWindowIsLoading(true));
			authService
				.logOut()
				.then(() => {
					dispatch(setIsAuthenticated(false));
					history.push('/login');
				})
				.finally(() => dispatch(setWindowIsLoading(false)));

			dispatch(setCurrentOffer(null));
			dispatch(setCurrentConfiguration(null));
			dispatch(setCurrentPiece(null));
			dispatch(setCurrentCompanyBranch(null));
		}

		setConfirmationModalIsActive(false);
	};

	const renderContent = () => {
		let viewToRender;

		switch (currentView) {
			case PROFILE:
				viewToRender = renderProfileSettings();
				break;
			case CHANGE_PASSWORD:
				viewToRender = renderChangePassword();
				break;
			case COMPANY_SETTINGS:
				viewToRender = <CompanySettings />;
				break;
			case DELIVERY_ADDRESSES:
				viewToRender = <DeliveryAddressesManagement />;
				break;
			default:
				break;
		}

		return viewToRender;
	};

	const renderAppHeader = () => {
		let title;

		switch (currentView) {
			case PROFILE:
				title = t(prefix + 'profileSettings');
				break;
			case CHANGE_PASSWORD:
				title = t(prefix + 'changePassword');
				break;
			case COMPANY_SETTINGS:
				title = t(prefix + 'companySettings');
				break;
			case DELIVERY_ADDRESSES:
				title = t(prefix + 'deliveryAddresses');
				break;
			default:
				break;
		}

		return <AppHeader title={title} />;
	};

	return (
		<AppHolder>
			{windowIsLoading ? <LoadingMessage variant="overlay" /> : null}

			<ConfirmationModal
				isActive={confirmationModalIsActive}
				onClose={confirmed => closeConfirmationModal(confirmed)}
				content={t(prefix + 'logoutConfirmation')}
			/>
			<NavigationBar />

			<SideBar title={t(prefix + 'sidebarTitle')}>
				<SidebarListItem onClick={() => setCurrentView(PROFILE)} content={t(prefix + 'profileSettings')} />
				<SidebarListItem onClick={() => setCurrentView(CHANGE_PASSWORD)} content={t(prefix + 'changePassword')} />
				<SidebarListItem onClick={() => setCurrentView(COMPANY_SETTINGS)} content={t(prefix + 'companySettings')} />
				<SidebarListItem onClick={() => setCurrentView(DELIVERY_ADDRESSES)} content={t(prefix + 'deliveryAddresses')} />
				<SidebarListItem
					dataCy={'settings-logout-button'}
					onClick={() => setConfirmationModalIsActive(true)}
					content={t(prefix + 'logout')}
					cypressTestName="logoutButton"
				/>
			</SideBar>

			<AppContent>
				{renderAppHeader()}

				{renderContent()}
			</AppContent>

			<MessageHolder messages={alertMessages} onClose={index => dispatch(removeAlertMessage(index))} />
		</AppHolder>
	);
};
