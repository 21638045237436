import { Service } from './Service';
import { Parser } from '../helpers/Parser';

class UserService extends Service {
	async getUser() {
		return await this.authenticatedRequest('get', 'users').then(response => {
			if (response.data.success) response.data.data = Parser.parseUser(response.data.data);

			return response.data;
		});
	}

	async getUsersWithPendingRegistrations() {
		return await this.authenticatedRequest('get', 'users/pending').then(response => {
			if (response.data.success) {
				response.data.data = response.data.data.map(userData => Parser.parseUser(userData));
			}

			return response.data;
		});
	}

	async create(companyId, companyBranchId, user) {
		let data = user;
		data.companyId = companyId;
		data.companyBranchId = companyBranchId;

		return await this.authenticatedRequest('post', 'users', data).then(response => {
			if (response.data.success) {
				response.data.data = Parser.parseUser(response.data.data);
			}

			return response.data;
		});
	}

	async update(companyId, user) {
		return await this.authenticatedRequest('put', 'users/' + user.id, user).then(response => {
			if (response.data.success) {
				response.data.data = Parser.parseUser(response.data.data);
			}

			return response.data;
		});
	}

	async closePendingRegistration(userId, accepted) {
		return await this.authenticatedRequest('post', 'users/pending/' + userId, { accepted: accepted }).then(response => {
			return response.data;
		});
	}

	async changePassword(formData, userId) {
		return await this.authenticatedRequest('put', 'users/' + userId + '/password', formData)
			.then(response => {
				response.data.status = response.status;
				return response.data;
			})
			.catch(error => {
				throw Error(error);
			});
	}
}

export { UserService };
