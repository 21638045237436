import React, { useEffect, useState } from 'react';
import { AppHolder } from '../../layout/AppHolder';
import { LoadingMessage } from '../../messages/LoadingMessage';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { OrderService } from '../../../classes/services/OrderService';
import {
	addAlertMessage,
	removeAlertMessage,
	setSearchKeyword,
	setShouldUpdatePrice,
	setWindowIsLoading,
} from '../../../actions/GeneralActions';
import { DANGER } from '../../../constants/Variants';
import { AppContent } from '../../layout/AppContent';
import { NavigationBar } from '../../navigation/NavigationBar';
import { MessageHolder } from '../../messages/MessageHolder';
import { EmptyOrdersPage } from '../../empty/EmptyOrdersPage';
import { useParams } from 'react-router-dom';
import { usePrevious } from '../../../classes/hooks/usePrevious';
import history from '../../../config/history';
import { setCurrentOrder, setOrderPaginationSettings, setOrders } from '../../../actions/OrderActions';
import { OrderDetail } from './OrderDetail';
import { AppHeader } from '../../headers/AppHeader';
import { captureException } from '@sentry/react';
import { DeliveryInfoHolder } from './DeliveryInfoHolder';
import { CompanyBranchService } from '../../../classes/services/CompanyBranchService';
import { OrderStatusModal } from '../../modals/OrderStatusModal';
import { AdsolutOrderDetail } from './AdsolutOrderDetail';
import { OrderSideBar } from './OrderSideBar';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';
import { OrderDeliveryDateModal } from '../../modals/OrderDeliveryDateModal';
import { ADMIN, MULTI_BRANCH_USER, SUPERVISOR, USER } from '../../../constants/RoleNames';
import { OrderModal } from '../../modals/OrderModal';
import { EmptyPage } from '../../empty/EmptyPage';
import { debounce } from 'lodash';
import { ListControls } from '../../shared/listControls/ListControls';
import { NONE } from '../../../constants/Filters';
import { OrderTable } from './OrderTable';
import { setCurrentCompanyBranch } from '../../../actions/CompanyActions';
import { Order } from '../../../classes/models/Order';
import { DISCOUNTED } from '../../../constants/PriceTypes';
import { PaginationData } from '../../../classes/models/PaginationData';

function Orders() {
	const { t } = useTranslation(TRANSLATION_NAMESPACE);
	const prefix = 'pages.orders.';
	const [companyBranches, setCompanyBranches] = useState([]);
	const [orderToEdit, setOrderToEdit] = useState(null);
	const [orderDeliveryDateModalIsActive, setOrderDeliveryDateModalIsActive] = useState(false);
	const [orderModalIsActive, setOrderIsActive] = useState(false);
	const [sortOrdersBy, setSortOrdersBy] = useState(NONE);
	const [filterOrdersBy, setFilterOrdersBy] = useState(NONE);
	const [updateFranco, setUpdateFranco] = useState(false);

	const { alertMessages, priceType, shouldUpdatePrice, windowIsLoading, searchKeyword } = useSelector(
		state => state.generalReducer,
	);
	const { currentCompanyBranch } = useSelector(state => state.companyReducer);
	const { currentOrder, orders, paginationSettings } = useSelector(state => state.orderReducer);

	const previousCompanyBranch = usePrevious(currentCompanyBranch);
	const previousSearchKeyword = usePrevious(searchKeyword);
	const previousPage = usePrevious(paginationSettings.currentPage);
	const previousAdministrativeStatus = usePrevious(filterOrdersBy);
	const dispatch = useDispatch();
	const urlParameters = useParams();
	const orderService = new OrderService();
	const companyBranchService = new CompanyBranchService();

	useEffect(() => {
		let isMounted = true;

		if (!hasOneOfRoles([USER, MULTI_BRANCH_USER])) {
			dispatch(setWindowIsLoading(true));

			companyBranchService
				.getAll()
				.then(response => {
					if (response.success) {
						if (isMounted) setCompanyBranches(response.data);
					} else {
						throw Error(response.message);
					}
				})
				.catch(error => {
					dispatch(addAlertMessage(DANGER, t(prefix + 'fetchCompanyBranchesFailed')));
					throw error;
				});
		}
		if (hasOneOfRoles(MULTI_BRANCH_USER)) {
			companyBranchService.getByCompany().then(response => {
				if (response.success && isMounted) {
					setCompanyBranches(response.data);
				}
			});
		}

		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		let shouldFetchOrders = false;
		let shouldLoadFirstPage = false;
		let shouldUpdateFranco = false;

		if (previousAdministrativeStatus !== filterOrdersBy) {
			shouldLoadFirstPage = true;
			shouldFetchOrders = true;
		}

		if (previousPage !== paginationSettings.currentPage) {
			shouldFetchOrders = true;
		}

		if (previousCompanyBranch?.id !== currentCompanyBranch?.id && !searchKeyword) {
			if (currentCompanyBranch?.id) {
				shouldUpdateFranco = true;
			}
			if (paginationSettings.current !== 1) {
				shouldLoadFirstPage = true;
			}
			shouldFetchOrders = true;
		}

		if (shouldUpdatePrice) shouldFetchOrders = true;

		if (searchKeyword && previousSearchKeyword != searchKeyword) {
			shouldFetchOrders = true;
			dispatch(setCurrentCompanyBranch(null));
		}

		if (shouldFetchOrders) {
			getAllOrders(currentCompanyBranch, shouldLoadFirstPage);
			if (shouldUpdateFranco) setUpdateFranco(true);
			if (shouldUpdatePrice) dispatch(setShouldUpdatePrice(false));
		}
	}, [shouldUpdatePrice, currentCompanyBranch, searchKeyword, paginationSettings.currentPage, filterOrdersBy]);

	useEffect(() => {
		if (urlParameters?.id) {
			const newActiveOrder = orders.find(order => order.id === parseInt(urlParameters.id));

			if (newActiveOrder) {
				let allowedToView;

				if (hasOneOfRoles([ADMIN, SUPERVISOR])) {
					allowedToView = true;
				} else {
					allowedToView = newActiveOrder.isVisible;
				}

				if (allowedToView) {
					if (!currentCompanyBranch || currentCompanyBranch.id !== newActiveOrder.companyBranch.id) {
						companyBranchService.getById(newActiveOrder.companyBranch.id).then(response => {
							dispatch(setCurrentCompanyBranch(response.data));
						});
					}
					dispatch(setCurrentOrder(newActiveOrder));
				}
			}
		} else {
			dispatch(setCurrentOrder());
		}
	}, [urlParameters, orders]);

	const updateSearchKeyword = debounce(keyword => {
		dispatch(setSearchKeyword(keyword));
	}, 650);

	const getAllOrders = (companyBranch, shouldLoadFirstPage) => {
		dispatch(setWindowIsLoading(true));

		let priceTypeForOrders = priceType;

		if (!priceTypeForOrders) {
			priceTypeForOrders = DISCOUNTED;
		}

		orderService
			.getAll(
				priceTypeForOrders,
				companyBranch,
				searchKeyword,
				shouldLoadFirstPage ? 1 : paginationSettings.currentPage,
				filterOrdersBy,
			)
			.then(response => {
				if (response.success) {
					dispatch(setOrders(response.data.data));
					dispatch(setOrderPaginationSettings(new PaginationData(response.data)));
				} else {
					dispatch(addAlertMessage(DANGER, t(prefix + 'getOrdersFailed')));
				}
			})
			.catch(() => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'getOrdersFailed')));
			})
			.finally(() => dispatch(setWindowIsLoading(false)));
	};

	const updateHandlerOfOrder = orderId => {
		orderService
			.updateAdministrativeStatus(orderId)
			.then(updatedOrder => {
				dispatch(
					setOrders(
						orders.map(order => {
							if (order.id === updatedOrder.id) {
								order.handler = updatedOrder.handler;
								order.administrativeStatus = updatedOrder.administrativeStatus;

								return order;
							}

							return order;
						}),
					),
				);
			})
			.catch(error => {
				dispatch(addAlertMessage(DANGER, t(prefix + 'updateHandlerFailed')));
				captureException(error);
			});
	};

	const closeOrderStatusModal = statuses => {
		if (statuses) {
			dispatch(setWindowIsLoading(true));

			orderService
				.updateStatus(orderToEdit.id, statuses)
				.then(updatedOrder => {
					dispatch(
						setOrders(
							orders.map(order => {
								if (order.id === updatedOrder.id) {
									order.statuses = updatedOrder.statuses;
									order.isModified = updatedOrder.isModified;
									order.deliveryDate = updatedOrder.deliveryDate;
								}

								return order;
							}),
						),
					);

					setOrderToEdit(null);
				})
				.catch(error => {
					dispatch(addAlertMessage(DANGER, t(prefix + 'updatingStatusFailed')));

					captureException(error);
				})
				.finally(() => dispatch(setWindowIsLoading(false)));
		} else {
			setOrderToEdit(null);
		}
	};

	const renderEmptyComponent = () => {
		if (orders.length || windowIsLoading) return;

		return (
			<>
				{!!(searchKeyword || filterOrdersBy !== NONE) ? (
					<EmptyPage title={t(prefix + 'noOrdersFound')} />
				) : (
					<EmptyOrdersPage />
				)}
			</>
		);
	};

	const renderOrderTable = () => {
		if (!orders.length || currentOrder || windowIsLoading) return;

		return (
			<OrderTable
				orders={orders}
				onHandledClick={order => updateHandlerOfOrder(order.id)}
				onEditOrderStatusClick={order => setOrderToEdit(Object.assign(new Order(), order))}
				onOrderClick={order => changeSelectedOrder(order)}
				hasCompanyBranchSelected={!!currentCompanyBranch}
				companyBranches={companyBranches}
			/>
		);
	};

	const renderOrderDetail = () => {
		if (!currentOrder) return;

		let orderDetail;

		if (currentOrder.adsolutData && hasOneOfRoles(USER)) {
			orderDetail = (
				<AdsolutOrderDetail
					order={currentOrder}
					onEditOrderClick={() => setOrderIsActive(true)}
					onDeliveryDateUpdateClick={() => setOrderDeliveryDateModalIsActive(true)}
				/>
			);
		} else {
			orderDetail = (
				<OrderDetail
					order={currentOrder}
					onEditOrderClick={() => setOrderIsActive(true)}
					onDeliveryDateUpdateClick={() => setOrderDeliveryDateModalIsActive(true)}
				/>
			);
		}

		return (
			<>
				{orderDetail}
				<OrderSideBar order={currentOrder} />
			</>
		);
	};

	const changeSelectedOrder = order => {
		history.push('orders/' + order.id);
	};

	return (
		<AppHolder>
			{hasOneOfRoles([ADMIN, SUPERVISOR]) && (
				<OrderDeliveryDateModal
					isActive={orderDeliveryDateModalIsActive}
					onClose={() => setOrderDeliveryDateModalIsActive(false)}
					order={currentOrder}
				/>
			)}

			{hasOneOfRoles([ADMIN, SUPERVISOR]) && (
				<OrderModal isActive={orderModalIsActive} onClose={() => setOrderIsActive(false)} order={currentOrder} />
			)}

			{windowIsLoading ? <LoadingMessage variant="overlay" /> : null}

			<NavigationBar />

			<OrderStatusModal
				isActive={orderToEdit?.id > 0}
				onClose={statuses => closeOrderStatusModal(statuses)}
				order={orderToEdit}
			/>

			{!currentOrder && (
				<AppContent>
					<AppHeader title={t(prefix + 'sideBarTitle')}>
						<DeliveryInfoHolder
							styling="d-flex"
							updateFranco={updateFranco}
							onFrancoUpdated={() => setUpdateFranco(false)}
						/>
					</AppHeader>

					<ListControls
						branches={companyBranches}
						sortBy={sortOrdersBy}
						filterBy={filterOrdersBy}
						onUpdateFilter={value => setFilterOrdersBy(value)}
						onUpdateSorting={value => setSortOrdersBy(value)}
						onUpdateSearchKeyword={input => updateSearchKeyword(input)}
					/>

					{renderEmptyComponent()}
					{renderOrderTable()}
				</AppContent>
			)}

			{renderOrderDetail()}

			<MessageHolder messages={alertMessages} onClose={index => dispatch(removeAlertMessage(index))} />
		</AppHolder>
	);
}

export { Orders };
